import _ from "lodash";
import {
  DEMAND_TYPES,
  PRESETS,
  getGoogleEligibleDemandTypes,
  getPrebidDemandTypes,
  getGuarenteedDemandTypes,
} from "constants/DemandType";
import { GAM_COUNTRY } from "constants/GAMCountry";
import numeral from "numeral";

export function formatMoneyWithCurrency({ currency, value }) {
  return Number(value).toLocaleString("en-US", {
    style: "currency",
    currency,
  });
}

export const HOLISTIC_DEMAND_TYPES = [
  DEMAND_TYPES.AD_EXCHANGE,
  DEMAND_TYPES.PRICE_PRIORITY,
  DEMAND_TYPES.EXCHANGE_BIDDING, // open bidding included
  DEMAND_TYPES.NETWORK,
  DEMAND_TYPES.BULK,
];

export const DEMAND_TYPE_COLORS = new Map([
  [DEMAND_TYPES.AD_EXCHANGE, "#2caffe"],
  [DEMAND_TYPES.AD_SENSE, "#544fc5"],
  [DEMAND_TYPES.EXCHANGE_BIDDING, "#00e272"],
  [DEMAND_TYPES.PRICE_PRIORITY, "#fe6a35"],
  [DEMAND_TYPES.NETWORK, "#6b8abc"],
  [DEMAND_TYPES.BULK, "#d568fb"],
  [DEMAND_TYPES.HEADER_BIDDING, "#2ee0ca"],
  [DEMAND_TYPES.BILLABLE_HOUSE, "#fa4b42"],
  [DEMAND_TYPES.MEDIATION, "#f15c80"],
  [DEMAND_TYPES.SDK_BIDDING, "#91e8e1"],
  [DEMAND_TYPES.SPONSORSHIP, "#2b908f"],
  [DEMAND_TYPES.STANDARD, "#434348"],
  [DEMAND_TYPES.PREFERRED_DEAL, "#90ed7d"],
  [DEMAND_TYPES.HOUSE, "#8085e9"],
]);

export function _parseData({ columns, records }, field, timeKey, monthList) {
  const fieldIndex = columns.indexOf(field);
  const timeIndex = columns.indexOf(timeKey);

  const result = _.map(
    _.groupBy(records, (record) => record[fieldIndex]),
    (records, name) => {
      const data = {};
      _.forEach(columns, (col, index) => {
        if (col !== field && col !== timeKey) {
          const temp = Array(monthList.length).fill(0);
          records.forEach((record) => {
            const monthIndex = monthList.indexOf(record[timeIndex]);
            temp[monthIndex] = record[index];
          });
          data[col] = temp;
        }
      });

      return {
        [field]: name,
        ...data,
      };
    }
  );
  return result;
}

export function _getSortedOption(data, valKey, uniqKey, allOption) {
  const options = _.map(allOption, (d) => _.toLower(d));
  let total = _.sumBy(data, (item) => _.sum(item[valKey]));

  const sortData = _.orderBy(
    _.map(data, (item) => {
      const sum = _.sum(item[valKey]);
      const percentage = _.round((sum / total) * 100, 1);

      return {
        ...item,
        sum,
        percentage,
      };
    }),
    ["sum"],
    ["desc"]
  );

  const sortDataList = _.map(sortData, (r) => _.toLower(r[uniqKey]));

  const leftList = _.difference(options, sortDataList);

  const result = _.map([...sortDataList, ...leftList], (r) => {
    const item = _.find(sortData, (d) => _.toLower(d[uniqKey]) === r);
    return options.includes(r)
      ? {
          value: r,
          label: `${_.startCase(r)} (${item ? item.percentage : "0"}%)`,
        }
      : undefined;
  }).filter((r) => r);

  return result;
}

export function formatNumber(float) {
  return _.toNumber(float.toFixed(2));
}

export function formatThousands(num) {
  return numeral(num).format("0,0.0a");
  // if (num >= 1000000) {
  //   return (
  //     (num / 1000000)
  //       .toLocaleString(undefined, {
  //         minimumFractionDigits: 1,
  //         maximumFractionDigits: 1,
  //       })
  //       .replace(/\.0$/, "") + "M"
  //   );
  // } else if (num >= 1000) {
  //   return (
  //     (num / 1000)
  //       .toLocaleString(undefined, {
  //         minimumFractionDigits: 1,
  //         maximumFractionDigits: 1,
  //       })
  //       .replace(/\.0$/, "") + "K"
  //   );
  // } else {
  //   return num.toLocaleString();
  // }
}

export const DEMAND_TYPE_GROUP = new Map()
  .set("Holistic", HOLISTIC_DEMAND_TYPES)
  .set(PRESETS.GOOGLE_ELIGIBLE, getGoogleEligibleDemandTypes())
  .set(PRESETS.PREBID, getPrebidDemandTypes())
  .set(PRESETS.BILLABLE_HOUSE_ONLY, [DEMAND_TYPES.BILLABLE_HOUSE])
  .set(
    PRESETS.PREBID_WITHOUT_BILLABLE_HOUSE,
    _.filter(getPrebidDemandTypes(), (t) => t !== DEMAND_TYPES.BILLABLE_HOUSE)
  )
  .set(PRESETS.GUARENTEED, getGuarenteedDemandTypes());

export const REGION_GROUP = new Map()
  .set("Asia", [
    GAM_COUNTRY[2156],
    GAM_COUNTRY[2158],
    GAM_COUNTRY[2344],
    GAM_COUNTRY[2392],
    GAM_COUNTRY[2410],
    GAM_COUNTRY[2446],
    GAM_COUNTRY[2496],
    GAM_COUNTRY[2104],
    GAM_COUNTRY[2764],
    GAM_COUNTRY[2704],
    GAM_COUNTRY[2418],
    GAM_COUNTRY[2116],
    GAM_COUNTRY[2458],
    GAM_COUNTRY[2702],
    GAM_COUNTRY[2360],
    GAM_COUNTRY[2096],
    GAM_COUNTRY[2626],
    GAM_COUNTRY[2004],
    GAM_COUNTRY[2356],
    GAM_COUNTRY[2586],
    GAM_COUNTRY[2050],
    GAM_COUNTRY[2064],
    GAM_COUNTRY[2524],
    GAM_COUNTRY[2462],
    GAM_COUNTRY[2144],
    GAM_COUNTRY[2398],
    GAM_COUNTRY[2417],
    GAM_COUNTRY[2762],
    GAM_COUNTRY[2795],
    GAM_COUNTRY[2860],
    GAM_COUNTRY[2048],
    GAM_COUNTRY[2368],
    GAM_COUNTRY[2376],
    GAM_COUNTRY[2400],
    GAM_COUNTRY[2414],
    GAM_COUNTRY[2422],
    GAM_COUNTRY[2512],
    GAM_COUNTRY[2275],
    GAM_COUNTRY[2634],
    GAM_COUNTRY[2682],
    GAM_COUNTRY[2736],
    GAM_COUNTRY[2792],
    GAM_COUNTRY[2784],
    GAM_COUNTRY[2887],
  ])
  .set("North America", [
    GAM_COUNTRY[2840],
    GAM_COUNTRY[2124],
    GAM_COUNTRY[2484],
    GAM_COUNTRY[2060],
    GAM_COUNTRY[2044],
    GAM_COUNTRY[2052],
    GAM_COUNTRY[2388],
    GAM_COUNTRY[2780],
    GAM_COUNTRY[2659],
    GAM_COUNTRY[2662],
    GAM_COUNTRY[2670],
    GAM_COUNTRY[2214],
    GAM_COUNTRY[2332],
  ])
  .set("South America", [
    GAM_COUNTRY[2032],
    GAM_COUNTRY[2076],
    GAM_COUNTRY[2152],
    GAM_COUNTRY[2170],
    GAM_COUNTRY[2218],
    GAM_COUNTRY[2328],
    GAM_COUNTRY[2600],
    GAM_COUNTRY[2604],
    GAM_COUNTRY[2858],
    GAM_COUNTRY[2862],
    GAM_COUNTRY[2068],
  ])
  .set("Europe", [
    GAM_COUNTRY[2250],
    GAM_COUNTRY[2276],
    GAM_COUNTRY[2826],
    GAM_COUNTRY[2040],
    GAM_COUNTRY[2756],
    GAM_COUNTRY[2528],
    GAM_COUNTRY[2442],
    GAM_COUNTRY[2492],
    GAM_COUNTRY[2208],
    GAM_COUNTRY[2246],
    GAM_COUNTRY[2352],
    GAM_COUNTRY[2578],
    GAM_COUNTRY[2752],
    GAM_COUNTRY[2234],
    GAM_COUNTRY[2744],
    GAM_COUNTRY[2380],
    GAM_COUNTRY[2724],
    GAM_COUNTRY[2620],
    GAM_COUNTRY[2300],
    GAM_COUNTRY[2336],
    GAM_COUNTRY[2674],
    GAM_COUNTRY[2470],
    GAM_COUNTRY[2020],
    GAM_COUNTRY[2643],
    GAM_COUNTRY[2112],
    GAM_COUNTRY[2804],
    GAM_COUNTRY[2203],
    GAM_COUNTRY[2530],
    GAM_COUNTRY[2703],
    GAM_COUNTRY[2642],
    GAM_COUNTRY[2100],
    GAM_COUNTRY[2498],
    GAM_COUNTRY[2008],
    GAM_COUNTRY[2070],
    GAM_COUNTRY[2191],
    GAM_COUNTRY[2499],
    GAM_COUNTRY[2807],
    GAM_COUNTRY[2688],
    GAM_COUNTRY[2900],
  ])
  .set("Africa", [
    GAM_COUNTRY[2012],
    GAM_COUNTRY[2818],
    GAM_COUNTRY[2434],
    GAM_COUNTRY[2504],
    GAM_COUNTRY[2732],
    GAM_COUNTRY[2788],
    GAM_COUNTRY[2736],
    GAM_COUNTRY[2024],
    GAM_COUNTRY[2204],
    GAM_COUNTRY[2072],
    GAM_COUNTRY[2854],
    GAM_COUNTRY[2108],
    GAM_COUNTRY[2132],
    GAM_COUNTRY[2120],
    GAM_COUNTRY[2140],
    GAM_COUNTRY[2148],
    GAM_COUNTRY[2174],
    GAM_COUNTRY[2178],
    GAM_COUNTRY[2180],
    GAM_COUNTRY[2262],
    GAM_COUNTRY[2266],
    GAM_COUNTRY[2270],
    GAM_COUNTRY[2288],
    GAM_COUNTRY[2324],
    GAM_COUNTRY[2624],
    GAM_COUNTRY[2384],
    GAM_COUNTRY[2404],
    GAM_COUNTRY[2426],
    GAM_COUNTRY[2430],
    GAM_COUNTRY[2450],
    GAM_COUNTRY[2454],
    GAM_COUNTRY[2466],
    GAM_COUNTRY[2478],
    GAM_COUNTRY[2480],
    GAM_COUNTRY[2508],
    GAM_COUNTRY[2562],
    GAM_COUNTRY[2566],
    GAM_COUNTRY[2646],
    GAM_COUNTRY[2686],
    GAM_COUNTRY[2694],
    GAM_COUNTRY[2706],
    GAM_COUNTRY[2710],
    GAM_COUNTRY[2834],
    GAM_COUNTRY[2768],
    GAM_COUNTRY[2800],
    GAM_COUNTRY[2804],
    GAM_COUNTRY[2894],
    GAM_COUNTRY[2716],
  ])
  .set("Oceania", [
    GAM_COUNTRY[2036],
    GAM_COUNTRY[2554],
    GAM_COUNTRY[2242],
    GAM_COUNTRY[2598],
    GAM_COUNTRY[2090],
    GAM_COUNTRY[2548],
    GAM_COUNTRY[2882],
    GAM_COUNTRY[2776],
    GAM_COUNTRY[2798],
    GAM_COUNTRY[2296],
    GAM_COUNTRY[2585],
    GAM_COUNTRY[2583],
    GAM_COUNTRY[2584],
  ]);
