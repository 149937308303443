import _ from "lodash";

import { api, fetchRespJson, postData } from "apis/base";

export default {
  // network/ad units onboarding management
  validateOnboardGamAdUnits,
  onboardGamAdUnits,

  getOnboardNetworkCandidates,
  onboardNetwork,
};

async function getOnboardNetworkCandidates({ accountId }) {
  let apiUrl = `${api}/gam-account/${accountId}/network-candidates`;
  return await fetchRespJson(apiUrl);
}

async function onboardNetwork(params) {
  let apiUrl = `${api}/gam-account/${params.accountId}/onboard-network`;
  return await postData(apiUrl, params);
}

async function validateOnboardGamAdUnits(params) {
  let apiUrl = `${api}/validate-onboard-gam-ad-units`;
  return await postData(apiUrl, params);
}

async function onboardGamAdUnits(params) {
  let apiUrl = `${api}/onboard-gam-ad-units`;
  return await postData(apiUrl, params);
}
