import React, { useState } from "react";
import _ from "lodash";

import { NetworkAPI } from "apis";
import LoadingUI from "../../../common/LoadingUI";
import NetworkInfoHeader from "../../common/NetworkInfoHeader";
import CustomTargetingPopover from "./CustomTargetingPopover";
import UprTargetingPopover from "../custom-upr/UprTargetingPopover";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import { formatMoneyWithCurrency } from "components/intowow-business/platform-index/PlatformIndexReportsHelper";
import UprPriceSectionPopover from "../custom-upr/UprPriceSectionPopover";
import HighLightRowWrapper from "components/common/HighLightRowWrapper";

const buttonActionClass =
  "px-2 py-1 bg-gray-200 text-xs rounded border border-gray-400 shadow hover:bg-gray-300 text-gray-900 font-semibold";

const buttonClass =
  "px-2 py-1 bg-gray-100 text-xs rounded border border-gray-400 hover:bg-gray-200 text-gray-900 font-semibold";

class NetworkPrebidSettingViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isLoading: false,

      networkId: null,
      networkInfo: null,
      errMsg: "",
      settings: null,

      units: [],
      virtualPlacements: [],

      labels: [],
      configsByLabel: [],

      isJsonView: false,

      isTriggerLoading: false,
      updateCacheErrMsg: null,
    };
    this.toggleJsonView = this.toggleJsonView.bind(this);
    this.scrollToLabel = this.scrollToLabel.bind(this);
    this.handleUpdatePrebidCache = this.handleUpdatePrebidCache.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { networkId } = this.props.match.params;
    if (!networkId) {
      this.setState({ errMsg: "Missing Network ID" });
    } else {
      document.title = `${networkId} Prebid Settings | YB Observer`;
      this.setState({ networkId });
    }

    const networkInfo = await NetworkAPI.getNetworkInfo({
      networkId,
    });
    this.setState({
      networkId,
      networkInfo,
    });

    try {
      const units = await NetworkAPI.getUnitsByNetworkId({ networkId });
      const virtualPlacements = await NetworkAPI.getNetworkVirtualPlacements({
        networkId,
      });
      const settings = await NetworkAPI.getPrebidSettings({ networkId });

      const { config } = await NetworkAPI.getRawNetworkFeatures({ networkId });
      // console.log(config);
      const isCstPrebidEnabled = _.get(config, "enableCSTDynamicPrebid");
      const isCstPrebidCatchallDisabled = _.get(
        config,
        "disableCSTDynamicPrebidForCatchall"
      );

      const configsByLabel = _.groupBy(settings.config, "label");
      const labels = _.keys(configsByLabel).map((label) => {
        // label could be undefined
        return {
          name: `${label === "undefined" ? "No label" : label} (${
            configsByLabel[label].length
          })`,
          value: label,
          numOfItems: configsByLabel[label].length,
        };
      });

      this.setState({
        settings,
        isCstPrebidEnabled,
        isCstPrebidCatchallDisabled,

        units,
        virtualPlacements,

        labels,
        configsByLabel,

        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  toggleJsonView() {
    this.setState({ isJsonView: !this.state.isJsonView });
  }

  async handleUpdatePrebidCache() {
    const { networkId } = this.state;
    if (!networkId) return;
    const userConfirm = window.confirm(
      `Update Prebid Cache for network ${networkId}? (This could take up to 15 minutes)`
    );
    if (!userConfirm) return;

    this.setState({ isTriggerLoading: true, updateCacheErrMsg: null });

    try {
      const r = await NetworkAPI.triggerUpdatePrebidCache({ networkId });
      console.log(r);

      window.alert(
        "Update prebid cache successfully triggered. Please wait about 15 min for it to finish updating."
      );

      this.setState({ isTriggerLoading: false });
    } catch (err) {
      this.setState({
        updateCacheErrMsg: typeof err !== "string" ? err.toString() : err,
        isTriggerLoading: false,
      });
    }
  }

  scrollToLabel(label) {
    const element = document.getElementById(label);

    if (element) {
      window.scrollTo({
        behavior: "auto",
        top: element.offsetTop - 56,
      });
    }
  }

  render() {
    const {
      networkId,
      networkInfo,

      isLoading,
      errMsg,
      settings,
      isCstPrebidEnabled,
      isCstPrebidCatchallDisabled,

      units,
      virtualPlacements,

      labels,
      configsByLabel,

      isJsonView,

      isTriggerLoading,
      updateCacheErrMsg,
    } = this.state;

    return (
      <div>
        {networkInfo && (
          <NetworkInfoHeader
            // networkId={networkId}
            networkInfo={networkInfo}
          ></NetworkInfoHeader>
        )}

        <div className="min-h-screen bg-gray-200 px-12 py-8">
          <div className="flex items-center justify-between">
            <PageTitle title="Network Prebid Settings"></PageTitle>
          </div>
          {settings && (
            <div className="flex items-center gap-2">
              <span
                className={`rounded-full px-4 py-1 text-sm font-semibold ${
                  isCstPrebidEnabled
                    ? "bg-green-200 text-green-700"
                    : "bg-red-200 text-red-700"
                }`}
              >
                {isCstPrebidEnabled
                  ? "CST Dynamic Prebid Enabled"
                  : "CST Dynamic Prebid Disabled"}
              </span>
              <span
                className={`rounded-full px-4 py-1 text-sm font-semibold ${
                  isCstPrebidCatchallDisabled
                    ? "bg-red-200 text-red-700"
                    : "bg-green-200 text-green-700"
                }`}
              >
                {isCstPrebidCatchallDisabled
                  ? "CST Dynamic Prebid for Catchall Disabled"
                  : "CST Dynamic Prebid for Catchall Enabled"}
              </span>
            </div>
          )}

          {isLoading && <LoadingUI></LoadingUI>}
          {errMsg && <div className="text-red-800">{errMsg}</div>}

          {settings && (
            <div>
              <div className="flex w-full">
                <div className="w-3/4">
                  <div className="mb-1 mt-2 flex items-center justify-between">
                    <div className="font-semibold">
                      Configs ({settings.config ? settings.config.length : 0}):{" "}
                    </div>

                    <button
                      className={buttonClass}
                      onClick={this.toggleJsonView}
                    >
                      {!isJsonView ? "View In JSON" : "View in table"}
                    </button>
                  </div>

                  {settings.config && (
                    <>
                      {isJsonView ? (
                        <textarea
                          style={{ width: "100%", height: "600px" }}
                          defaultValue={JSON.stringify(settings, null, 4)}
                          readOnly
                        ></textarea>
                      ) : (
                        <div style={{ marginBottom: "600px" }}>
                          <div className="mb-4 flex flex-wrap items-center text-sm">
                            <div className="mr-1 font-semibold text-gray-700">
                              Jump to:{" "}
                            </div>
                            {labels.map((label) => {
                              return (
                                <button
                                  key={label.value}
                                  type="button"
                                  className="border rounded mb-1 mr-1 border-gray-200 bg-white px-2 py-1 text-gray-800 hover:border-blue-400"
                                  onClick={() => {
                                    this.scrollToLabel(label.value);
                                  }}
                                >
                                  {label.name}
                                </button>
                              );
                            })}
                          </div>

                          {labels.map((label) => {
                            return (
                              <div
                                id={label.value}
                                key={label.value}
                                className="border mb-8 border-gray-400"
                              >
                                <div className="flex items-center justify-between  bg-white p-4 text-xl font-semibold text-gray-800">
                                  {label.value === "undefined"
                                    ? "No label"
                                    : label.value}
                                  <div>{label.numOfItems}</div>
                                </div>

                                <ConfigsTable
                                  networkId={networkId}
                                  currency={networkInfo.currency}
                                  items={configsByLabel[label.value]}
                                  units={units}
                                  virtualPlacements={virtualPlacements}
                                ></ConfigsTable>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className="w-1/4 pl-4">
                  <div className="mb-4 text-sm font-semibold text-gray-700">
                    <div className="text-gray-600">Created at:</div>
                    <DateTimeFormatter
                      datetime={settings.createdAt}
                    ></DateTimeFormatter>
                  </div>

                  <div className="text-sm font-semibold text-gray-700">
                    <div className="text-gray-600">Updated at:</div>
                    <DateTimeFormatter
                      datetime={settings.updatedAt}
                    ></DateTimeFormatter>
                  </div>

                  <div className="mt-4 py-4">
                    <div className=" bg-white p-4">
                      <div className="flex items-center justify-center">
                        <button
                          type="button"
                          className={buttonActionClass}
                          onClick={this.handleUpdatePrebidCache}
                        >
                          Update Prebid Cache
                        </button>
                      </div>
                      <div>
                        {isTriggerLoading && (
                          <LoadingUI iconOnly={true}></LoadingUI>
                        )}
                        {updateCacheErrMsg && (
                          <div className="font-semibold text-red-600">
                            {updateCacheErrMsg}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function ConfigsTable({
  items,
  units,
  virtualPlacements,
  networkId,
  currency,
}) {
  const [popupId, setPopupId] = useState(-1);

  return (
    <table className="table w-full text-sm">
      <thead className="border-b bg-gray-200 text-xs text-blue-800">
        <tr>
          <th className="border px-2 py-1 text-center ">#</th>
          <th className="border px-2 py-1 text-right ">Version</th>
          <th className="border px-2 py-1 text-right ">Label</th>
          <th className="border px-2 py-1 text-right">Prices</th>
          <th className="border px-2 py-1 text-right ">Custom Targetings</th>
          <th className="border px-2 py-1 text-center ">Extra Targetings</th>
        </tr>
      </thead>
      <tbody className="bg-white  text-gray-900">
        {items.map((item, i) => {
          const floor =
            _.get(item, ["priceSection", "price"], null) || item.floor;

          return (
            <HighLightRowWrapper key={i} selected={popupId === i}>
              <td className="border px-2 py-1 text-center">{i + 1}</td>
              <td className="border px-2 py-1 text-right">{item.version}</td>
              <td className="border px-2 py-1 text-right">{item.label}</td>
              <td className="border px-2 py-1 text-right font-mono">
                {item.priceSection ? (
                  <PriceSectionView
                    item={item}
                    networkId={networkId}
                    currency={currency}
                    onOpen={() => setPopupId(i)}
                    onClose={() => setPopupId(-1)}
                  ></PriceSectionView>
                ) : (
                  item.floor
                )}
              </td>
              <td className="border px-2 py-1 text-right">
                {item.customTargetings.map((ct) => {
                  return (
                    <CustomTargetingPopover
                      key={ct.id}
                      title={`Floor: ${floor}`}
                      networkId={networkId}
                      customTargeting={ct}
                      units={units}
                      virtualPlacements={virtualPlacements}
                      triggerElement={
                        <div className="ml-3 inline-block cursor-pointer text-blue-600 hover:text-blue-700 hover:underline">
                          {ct.name}
                        </div>
                      }
                      onOpen={() => setPopupId(i)}
                      onClose={() => setPopupId(-1)}
                    ></CustomTargetingPopover>
                  );
                })}
              </td>
              <td className="border px-2 py-1 text-center">
                {item.extraTargetings && (
                  <UprTargetingPopover
                    networkId={networkId}
                    units={units}
                    virtualPlacements={virtualPlacements}
                    targetingSection={item.extraTargetings}
                    triggerElement={
                      <div className="inline-block cursor-pointer  text-blue-600 hover:text-blue-700 hover:underline">
                        {Object.keys(item.extraTargetings).join(", ")}
                      </div>
                    }
                    onOpen={() => setPopupId(i)}
                    onClose={() => setPopupId(-1)}
                  ></UprTargetingPopover>
                )}
              </td>
            </HighLightRowWrapper>
          );
        })}
      </tbody>
    </table>
  );
}

// price type: 0:floor, 1:targetCPM, 2: LGO
const PRICE_TYPE = {
  0: "Floor",
  1: "Target CPM",
  2: "LGO", // let google optimize
};
function PriceSectionView({ item, networkId, currency, onOpen, onClose }) {
  return (
    <div>
      <div className="-mx-2 px-2">
        <div className="text-xs font-medium leading-none text-gray-600">
          {/* Default */}
          {PRICE_TYPE[item.priceSection.priceType]}
        </div>
        <div className="font-mono">
          {formatMoneyWithCurrency({
            currency: currency,
            value: item.priceSection.price,
          })}
        </div>
      </div>
      {item.priceSection.brandedPriceSettings && (
        <div className="border-t">
          <UprPriceSectionPopover
            networkId={networkId}
            currency={currency}
            targetingSection={item.priceSection}
            triggerElement={
              <div className="cursor-pointer hover:underline">
                <BrandedPriceView
                  item={item.priceSection}
                  currency={currency}
                ></BrandedPriceView>
              </div>
            }
            onOpen={onOpen}
            onClose={onClose}
          ></UprPriceSectionPopover>
        </div>
      )}
    </div>
  );
}

function BrandedPriceView({ item }) {
  const { brandedPriceSettings } = item;

  return (
    <div>
      <span className="rounded bg-green-200 px-2 text-xs text-green-900">
        Branded ({brandedPriceSettings.length})
      </span>
    </div>
  );
}

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}

export default NetworkPrebidSettingViewer;
