import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { AnalysisReportsAPI } from "apis";
import { IoIosArrowDown } from "react-icons/io";
import Select from "react-select";

import PopupWrapper from "components/common/PopupWrapper";
import HighchartWrapper from "components/common/HighchartWrapper";
import DateRangePopover from "components/playground/home-dashboard-v2/DateRangePopover";
import NetworksSelector from "components/ops-mgmt/account/NetworksSelector";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import { DATE_RANGE_TYPE } from "components/playground/home-dashboard-v2/DateHelper";
import Checkbox from "components/common/Checkbox";
import LoadingUI from "components/common/LoadingUI";
import NetworkInfoHeader from "components/ops-mgmt/common/NetworkInfoHeader";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";
import { getQueryStringToObject, setQueryString } from "helpers/QueryString";

import { REQUEST_TYPES } from "constants/RequestType";
import { DEMAND_TYPES, PRESETS } from "constants/DemandType";
import { GAM_COUNTRY } from "constants/GAMCountry";
import {
  REPORTKEY,
  REQUEST,
  COUNTRY,
  DEMAND,
  DEVICE,
} from "./constants/ReportKey";

import {
  _parseData,
  _getSortedOption,
  formatNumber,
  formatThousands,
  HOLISTIC_DEMAND_TYPES,
  REGION_GROUP,
  DEMAND_TYPE_GROUP,
  DEMAND_TYPE_COLORS,
  formatMoneyWithCurrency,
} from "./DataHelper";
import {
  _parseTrendOptions,
  _parsePercentOptions,
  _parseGaugeOptions,
  _parseLineOptions,
  _parseBarOptions,
} from "./ChartHelper";

const fixedWidthStyle = { maxWidth: "1280px", margin: "auto" };

class InventoryRevenueAnalysisDashboard extends React.Component {
  constructor(props) {
    super(props);

    const end = moment().startOf("month");

    this.state = {
      scrollTop: 0,
      isLoading: false,
      errMsg: null,
      isFolding: false,
      data: null,
      options: null,
      startDate: end
        .clone()
        .subtract(1, "year")
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: end.subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
      isForceReload: false,
      selectedNetworkId: null,
      selectedCurrency: null,
      selectedNetwork: null,

      monthRange: [],
      dataUpdatedTime: "",

      hotkeyRequestType: new Map(),
      hotkeyDemandType: new Map(),

      selectedRequestType: [],
      selectedDemandType: [],
      selectedCountry: [],

      optionRequestType: [],
      optionDemandType: [],
      optionCountry: [],
    };

    this.child = React.createRef();

    this.handleNetworksChanged = this.handleNetworksChanged.bind(this);
    this.handleClosePopover = this.handleClosePopover.bind(this);
    this.handleDateRangeFilterChanged =
      this.handleDateRangeFilterChanged.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.handleForceReloadChanged = this.handleForceReloadChanged.bind(this);

    this.handleFilterChanged = this.handleFilterChanged.bind(this);
    this.handleHotKeyChanged = this.handleHotKeyChanged.bind(this);

    this.handleScroll = this.handleScroll.bind(this);
  }

  async componentDidMount() {
    const {
      networkId,
      startDate,
      endDate,
      requestTypes,
      countries,
      demandTypes,
    } = getQueryStringToObject(this.props.location.search);

    const requestTypesList = requestTypes ? atob(requestTypes).split(",") : [];
    const countriesList = countries ? atob(countries).split(",") : [];
    const demandTypesList = demandTypes ? atob(demandTypes).split(",") : [];

    this.setState({
      selectedRequestType: this._parseListToSelected(
        requestTypesList,
        REQUEST_TYPES
      ),
      selectedDemandType: this._parseListToSelected(
        demandTypesList,
        DEMAND_TYPES
      ),
      selectedCountry: this._parseListToSelected(countriesList, GAM_COUNTRY),
    });

    startDate && this.setState({ startDate });
    endDate && this.setState({ endDate });
    networkId &&
      this.setState({ selectedNetworkId: _.toInteger(networkId) }, () => {
        this.handleGetData({
          startDate: startDate || undefined,
          endDate: endDate || undefined,
          selectedNetworkId: _.toInteger(networkId),
          initial: true,
        });
      });

    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.data && this.state.monthRange) {
      if (
        _.xor(this.state.selectedRequestType, prevState.selectedRequestType)
          .length > 0 ||
        _.xor(this.state.selectedDemandType, prevState.selectedDemandType)
          .length > 0 ||
        _.xor(this.state.selectedCountry, prevState.selectedCountry).length > 0
      ) {
        this.processData(this.state.data, this.state.monthRange);
      }
    }

    if (this.state.selectedNetwork && !prevState.selectedNetwork) {
      this.initialHotkey();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  initialData(payload, monthList) {
    const data = {
      [REPORTKEY.REQUEST]: _parseData(
        payload[REPORTKEY.REQUEST],
        REQUEST.TYPE,
        REQUEST.MONTHYEAR,
        monthList
      ),
      [REPORTKEY.COUNTRY]: _parseData(
        payload[REPORTKEY.COUNTRY],
        COUNTRY.COUNTRY,
        COUNTRY.MONTHYEAR,
        monthList
      ),
      [REPORTKEY.DEMAND]: _parseData(
        payload[REPORTKEY.DEMAND],
        DEMAND.DEMANDTYPE,
        DEMAND.MONTHYEAR,
        monthList
      ),
      [REPORTKEY.DEVICE]: _parseData(
        payload[REPORTKEY.DEVICE],
        DEVICE.CATEGORY,
        DEVICE.MONTHYEAR,
        monthList
      ),
    };

    this.setState({
      data,
      dataUpdatedTime: payload.data_generated_time,
    });

    return data;
  }

  initialFilter(data) {
    const { selectedCountry, selectedDemandType, selectedRequestType } =
      this.state;

    // Option Process for Request Type by Request
    const optionRequestType = _getSortedOption(
      data[REPORTKEY.REQUEST],
      REQUEST.REQUEST,
      REQUEST.TYPE,
      _.values(REQUEST_TYPES)
    );

    // Option Process for Country by Request
    const optionCountry = _getSortedOption(
      data[REPORTKEY.COUNTRY],
      COUNTRY.REQUEST,
      COUNTRY.COUNTRY,
      _.values(GAM_COUNTRY)
    );

    // Option Process for Demand Type by Impression
    const optionDemandType = _getSortedOption(
      data[REPORTKEY.DEMAND],
      DEMAND.IMPRESSION,
      DEMAND.DEMANDTYPE,
      _.values(DEMAND_TYPES)
    );

    this.setState({
      optionDemandType,
      optionRequestType,
      optionCountry,
      selectedCountry: _.map(optionCountry, (d) => d.value).filter((r) =>
        selectedCountry.includes(r)
      ),
      selectedDemandType: _.map(optionDemandType, (d) => d.value).filter((r) =>
        selectedDemandType.includes(r)
      ),
      selectedRequestType: _.map(optionRequestType, (d) => d.value).filter(
        (r) => selectedRequestType.includes(r)
      ),
    });
  }

  initialHotkey() {
    const {
      billableDemandTypes = [],
      billableRequestTypes = [],
      currency,
    } = this.state.selectedNetwork;
    const parseDemandType = _.filter(billableDemandTypes, (d) =>
      this._isInclude(_.values(DEMAND_TYPES), d)
    );
    const parseRequestType = _.filter(billableRequestTypes, (d) =>
      this._isInclude(_.values(REQUEST_TYPES), d)
    );

    const hotkeyRequestType = new Map();
    const hotkeyDemandType = new Map();

    hotkeyDemandType.set(PRESETS.NETWORK_BILLABLE, parseDemandType);
    for (const [key, value] of DEMAND_TYPE_GROUP) {
      hotkeyDemandType.set(key, value);
    }

    hotkeyRequestType.set("Billable", parseRequestType);
    this.setState({
      selectedCurrency: currency,
      hotkeyRequestType,
      hotkeyDemandType,
    });
  }

  processData(origin, monthList) {
    const {
      selectedCountry,
      selectedDemandType,
      selectedRequestType,
      selectedCurrency,
    } = this.state;
    const originData = _.cloneDeep(origin);

    const data = {
      [REPORTKEY.REQUEST]: _.filter(originData[REPORTKEY.REQUEST], (d) => {
        return this._isInclude(selectedRequestType, d[REQUEST.TYPE]);
      }),
      [REPORTKEY.COUNTRY]: _.filter(originData[REPORTKEY.COUNTRY], (d) => {
        return this._isInclude(selectedCountry, d[COUNTRY.COUNTRY]);
      }),
      [REPORTKEY.DEMAND]: _.filter(originData[REPORTKEY.DEMAND], (d) => {
        return this._isInclude(selectedDemandType, d[DEMAND.DEMANDTYPE]);
      }),
      [REPORTKEY.DEVICE]: originData[REPORTKEY.DEVICE],
    };

    const reqTypeImpression = _.map(
      _.zip(..._.map(data[REPORTKEY.REQUEST], (d) => d[REQUEST.IMPRESSION])),
      (list) => _.sum(list)
    );

    const reqTypeRequest = _.map(
      _.zip(..._.map(data[REPORTKEY.REQUEST], (d) => d[REQUEST.REQUEST])),
      (list) => _.sum(list)
    );

    const reqTypeUnfilled = _.map(
      _.zip(..._.map(data[REPORTKEY.REQUEST], (d) => d[REQUEST.UNFILLED])),
      (list) => _.sum(list)
    );

    const demTypeImpression = _.map(
      _.zip(..._.map(data[REPORTKEY.DEMAND], (d) => d[DEMAND.IMPRESSION])),
      (list) => _.sum(list)
    );

    const demTypeCode = _.map(
      _.zip(..._.map(data[REPORTKEY.DEMAND], (d) => d[DEMAND.CODE])),
      (list) => _.sum(list)
    );

    const demTypeRevenue = _.map(
      _.zip(..._.map(data[REPORTKEY.DEMAND], (d) => d[DEMAND.REVENUE])),
      (list) => _.sum(list)
    );

    const holisticFilter = _.filter(originData[REPORTKEY.DEMAND], (d) =>
      this._isInclude(HOLISTIC_DEMAND_TYPES, d[DEMAND.DEMANDTYPE])
    );

    const holisticRevenue = _.map(
      _.zip(..._.map(holisticFilter, (d) => d[DEMAND.REVENUE])),
      (list) => _.sum(list)
    );

    const holisticRequest = _.map(
      _.zip(..._.map(originData[REPORTKEY.REQUEST], (d) => d[REQUEST.REQUEST])),
      (list) => _.sum(list)
    );

    const sortCountryByRequest = _.orderBy(
      data[REPORTKEY.COUNTRY],
      (item) => _.sum(item[COUNTRY.REQUEST]),
      ["desc"]
    );
    const top9Country = sortCountryByRequest.slice(0, 9);
    const otherCountryByRequest = _.map(
      _.zip(..._.map(sortCountryByRequest.slice(9), (d) => d[COUNTRY.REQUEST])),
      (list) => _.sum(list)
    );

    const options = {
      holistic: {
        avgRequest: holisticRequest.length === 0 ? 0 : _.mean(holisticRequest),
        avgRevenue: holisticRevenue.length === 0 ? 0 : _.mean(holisticRevenue),
      },
      summary: {
        avgRequest: reqTypeRequest.length === 0 ? 0 : _.mean(reqTypeRequest),
        avgRevenue: demTypeRevenue.length === 0 ? 0 : _.mean(demTypeRevenue),
      },
      [REPORTKEY.REQUEST]: {
        trend: _parseTrendOptions({
          data: data[REPORTKEY.REQUEST].map((d) => {
            return {
              name: d[REQUEST.TYPE],
              data: d[REQUEST.REQUEST],
            };
          }),
          monthList,
          title: _.startCase(REQUEST.REQUEST),
        }),
        percent: _parsePercentOptions({
          data: data[REPORTKEY.REQUEST].map((d) => {
            return {
              name: d[REQUEST.TYPE],
              data: _.sum(d[REQUEST.REQUEST]),
            };
          }),
        }),
      },
      [REPORTKEY.DEVICE]: {
        trend: _parseTrendOptions({
          data: data[REPORTKEY.DEVICE].map((d) => {
            return {
              name: d[DEVICE.CATEGORY],
              data: d[DEVICE.REQUEST],
            };
          }),
          monthList,
          title: _.startCase(DEVICE.REQUEST),
        }),
        percent: _parsePercentOptions({
          data: data[REPORTKEY.DEVICE].map((d) => {
            return {
              name: d[DEVICE.CATEGORY],
              data: _.sum(d[DEVICE.REQUEST]),
            };
          }),
        }),
      },
      [REPORTKEY.COUNTRY]: {
        trend: _parseTrendOptions({
          data: [
            ...top9Country.map((d) => {
              return {
                name: d[COUNTRY.COUNTRY],
                data: d[COUNTRY.REQUEST],
              };
            }),
            {
              name: "Others",
              data: otherCountryByRequest,
            },
          ],
          monthList,
          title: _.startCase(COUNTRY.REQUEST),
        }),
        percent: _parsePercentOptions({
          data: [
            ...top9Country.map((d) => {
              return {
                name: d[COUNTRY.COUNTRY],
                data: _.sum(d[COUNTRY.REQUEST]),
              };
            }),
            {
              name: "Others",
              data: _.sum(otherCountryByRequest),
            },
          ],
        }),
      },
      [REPORTKEY.DEMAND]: {
        [DEMAND.IMPRESSION]: {
          trend: _parseTrendOptions({
            data: data[REPORTKEY.DEMAND].map((d) => {
              return {
                name: d[DEMAND.DEMANDTYPE],
                data: d[DEMAND.IMPRESSION],
                color: this._getDemandColor(d[DEMAND.DEMANDTYPE]),
              };
            }),
            monthList,
            title: _.startCase(DEMAND.IMPRESSION),
          }),
          percent: _parsePercentOptions({
            data: data[REPORTKEY.DEMAND].map((d) => {
              return {
                name: d[DEMAND.DEMANDTYPE],
                data: _.sum(d[DEMAND.IMPRESSION]),
                color: this._getDemandColor(d[DEMAND.DEMANDTYPE]),
              };
            }),
          }),
        },
        [DEMAND.REVENUE]: {
          trend: _parseTrendOptions({
            data: data[REPORTKEY.DEMAND].map((d) => {
              return {
                name: d[DEMAND.DEMANDTYPE],
                data: d[DEMAND.REVENUE],
                color: this._getDemandColor(d[DEMAND.DEMANDTYPE]),
              };
            }),
            monthList,
            title: _.startCase(DEMAND.REVENUE),
          }),
          percent: _parsePercentOptions({
            data: data[REPORTKEY.DEMAND].map((d) => {
              return {
                name: d[DEMAND.DEMANDTYPE],
                data: _.sum(d[DEMAND.REVENUE]),
                color: this._getDemandColor(d[DEMAND.DEMANDTYPE]),
              };
            }),
          }),
        },
        [DEMAND.DROPOFFF]: {
          trend: _parseLineOptions({
            data: data[REPORTKEY.DEMAND].map((d) => {
              return {
                name: d[DEMAND.DEMANDTYPE],
                data: d[DEMAND.IMPRESSION].map((r, i) =>
                  d[DEMAND.CODE][i] === 0
                    ? 0
                    : formatNumber((r / d[DEMAND.CODE][i]) * 100)
                ),
                color: this._getDemandColor(d[DEMAND.DEMANDTYPE]),
              };
            }),
            monthList,
            title: `${_.startCase(DEMAND.DROPOFFF)} (%)`,
            unit: "%",
          }),
          percent: _parseBarOptions({
            data: [
              {
                name: "CSC Drop off Rate",
                data: data[REPORTKEY.DEMAND].map((d) => ({
                  y: _.mean(
                    d[DEMAND.IMPRESSION].map((r, i) =>
                      d[DEMAND.CODE][i] === 0
                        ? 0
                        : formatNumber((r / d[DEMAND.CODE][i]) * 100)
                    )
                  ),
                  color: this._getDemandColor(d[DEMAND.DEMANDTYPE]),
                })),
              },
            ],
            categories: data[REPORTKEY.DEMAND].map((d) => d[DEMAND.DEMANDTYPE]),
            title: `${_.startCase(DEMAND.DROPOFFF)} (%)`,
            unit: "%",
          }),
        },
      },
      ecpm: {
        trend: _parseLineOptions({
          data: data[REPORTKEY.DEMAND].map((d) => {
            return {
              name: d[DEMAND.DEMANDTYPE],
              data: d[DEMAND.REVENUE].map((r, i) =>
                d[DEMAND.IMPRESSION][i] === 0
                  ? 0
                  : formatNumber((r * 1000) / d[DEMAND.IMPRESSION][i])
              ),
              color: this._getDemandColor(d[DEMAND.DEMANDTYPE]),
            };
          }),
          monthList,
          title: "eCPM",
        }),
        percent: _parseBarOptions({
          data: [
            {
              name: "eCPM",
              data: data[REPORTKEY.DEMAND].map((d) => ({
                y:
                  _.sum(d[DEMAND.IMPRESSION]) === 0
                    ? 0
                    : formatNumber(
                        (_.sum(d[DEMAND.REVENUE]) * 1000) /
                          _.sum(d[DEMAND.IMPRESSION])
                      ),
                color: this._getDemandColor(d[DEMAND.DEMANDTYPE]),
              })),
            },
          ],
          categories: data[REPORTKEY.DEMAND].map((d) => d[DEMAND.DEMANDTYPE]),
          title: "eCPM",
        }),
      },
      fillRate: {
        trend: _parseLineOptions({
          data: [
            {
              name: "Fill Rate",
              data: _.map(reqTypeImpression, (d, i) =>
                reqTypeRequest[i] === 0
                  ? 0
                  : formatNumber((d / reqTypeRequest[i]) * 100)
              ),
            },
            {
              name: "Unfilled Rate",
              data: _.map(reqTypeUnfilled, (d, i) =>
                reqTypeRequest[i] === 0
                  ? 0
                  : formatNumber((d / reqTypeRequest[i]) * 100)
              ),
            },
            {
              name: "Drop off Rate",
              data: _.map(demTypeImpression, (d, i) =>
                demTypeCode[i] === 0
                  ? 0
                  : formatNumber((d / demTypeCode[i]) * 100)
              ),
            },
          ],
          monthList,
          title: "Rate (%)",
          unit: "%",
        }),
        avg: _parseGaugeOptions({
          data: formatNumber(
            _.mean(
              _.map(reqTypeImpression, (d, i) =>
                reqTypeRequest[i] === 0 ? 0 : d / reqTypeRequest[i]
              )
            ) * 100
          ),
          limit: 0.9,
        }),
      },
      dropoff: {
        trend: _parseLineOptions({
          data: [
            {
              name: "Drop off Rate",
              data: _.map(demTypeImpression, (d, i) =>
                demTypeCode[i] === 0
                  ? 0
                  : formatNumber((d / demTypeCode[i]) * 100)
              ),
            },
          ],
          monthList,
          title: "Rate (%)",
          unit: "%",
        }),
        avg: _parseGaugeOptions({
          data: formatNumber(
            _.mean(
              _.map(demTypeImpression, (d, i) =>
                demTypeCode[i] === 0 ? 0 : d / demTypeCode[i]
              )
            ) * 100
          ),
        }),
      },
    };

    this.setState({
      isLoading: false,
      errMsg: null,
      options,
    });
  }

  handleScroll() {
    const { isFolding, scrollTop } = this.state;
    const currentPosition =
      window.pageYOffset || document.documentElement.scrollTop;

    if (Math.abs(scrollTop - currentPosition) < 20) return;

    this.setState({ scrollTop: currentPosition });

    if (isFolding && currentPosition < 50) this.setState({ isFolding: false });
    if (!isFolding && currentPosition > 150) {
      this.setState({ isFolding: true }, () => {
        window.scrollTo({
          top: 168,
        });
      });
    }
  }

  handleNetworksChanged(network) {
    const { networkId } = network;

    setQueryString(this.props, { networkId });

    this.setState({
      selectedNetworkId: networkId,
      selectedNetwork: network,
    });
  }

  handleClosePopover() {
    this.child.current.handleClosePopup();
  }

  handleDateRangeFilterChanged({ startDate, endDate }) {
    setQueryString(this.props, {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    });

    this.setState({
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    });
  }

  handleForceReloadChanged(value) {
    this.setState({ isForceReload: value });
  }

  async handleGetData({
    startDate = this.state.startDate,
    endDate = this.state.endDate,
    selectedNetworkId = this.state.selectedNetworkId,
    initial = false,
  }) {
    const { isForceReload } = this.state;

    try {
      this.setState({
        isLoading: true,
        errMsg: null,
        options: null,
        data: null,
      });

      const result =
        await AnalysisReportsAPI.getNetworkInventoryAnalysisReports({
          networkId: selectedNetworkId,
          startDate,
          endDate,
          forceReload: isForceReload,
        });

      // Reset filter when change network
      if (!initial) {
        this.setState({
          selectedRequestType: _.values(REQUEST_TYPES).map((r) => _.toLower(r)),
          selectedDemandType: _.values(DEMAND_TYPES).map((r) => _.toLower(r)),
          selectedCountry: _.values(GAM_COUNTRY).map((r) => _.toLower(r)),
        });

        this.initialHotkey();
      }

      const monthRange = this._getMonthRange(startDate, endDate);
      this.setState({
        data: result,
        monthRange,
      });

      const data = this.initialData(result, monthRange);
      this.initialFilter(data);
      this.processData(data, monthRange);
    } catch (error) {
      this.setState({ errMsg: error.toString(), isLoading: false });
    }
  }

  handleHotKeyChanged(hotkey, hotkeyMap, optionList, filterKey) {
    const list = hotkeyMap.get(hotkey) || [];
    this.setState({
      [filterKey]: _.map(
        _.filter(optionList, (o) => this._isInclude(list, o.value)),
        (o) => o.value
      ),
    });
  }

  handleFilterChanged(option, optionList, filterKey) {
    const list = (option || []).map((o) => o.value);
    this.setState({
      [filterKey]: _.map(
        _.filter(optionList, (o) => list.includes(o.value)),
        (o) => o.value
      ),
    });
  }

  _getMonthRange(start = this.state.startDate, end = this.state.endDate) {
    const startMonth = moment(start);
    const endMonth = moment(end);
    const monthDiff = endMonth.diff(startMonth, "months");

    const monthRange = [];
    for (let i = 0; i <= monthDiff; i++) {
      monthRange.push(startMonth.clone().add(i, "months").format("YYYY-MM"));
    }

    return monthRange;
  }

  _parseListToString(list, options) {
    return btoa(list.length === options.length ? "All" : list.join(","));
  }

  _parseListToSelected(list, enumValues) {
    const enumList = _.values(enumValues).map((r) => _.toLower(r));
    return list.length === 0 || (list.length === 1 && list[0] === "All")
      ? enumList
      : _.filter(list, (d) => enumList.includes(d));
  }

  _isInclude(list, target) {
    return list.map((d) => _.toLower(d)).includes(_.toLower(target));
  }

  _getDemandColor(target) {
    const parse = _.find(
      _.values(DEMAND_TYPES),
      (d) => _.toLower(d) === _.toLower(target)
    );
    return DEMAND_TYPE_COLORS.get(parse);
  }

  render() {
    const {
      startDate,
      endDate,
      isForceReload,
      selectedNetworkId,
      selectedCurrency,
      options,
      dataUpdatedTime,
      errMsg,
      isLoading,
      isFolding,
      selectedCountry,
      selectedDemandType,
      selectedRequestType,
      optionCountry,
      optionDemandType,
      optionRequestType,
      hotkeyRequestType,
      hotkeyDemandType,
    } = this.state;

    const isGettingDataDisabled = !startDate || !endDate || !selectedNetworkId;

    const parseRequestType = _.map(selectedRequestType, (r) => {
      return _.find(optionRequestType, { value: r });
    });

    const parseDemandType = _.map(selectedDemandType, (r) => {
      return _.find(optionDemandType, { value: r });
    });

    const parseCountry = _.map(selectedCountry, (r) => {
      return _.find(optionCountry, { value: r });
    });

    const queryString = setQueryString(
      this.props,
      {
        requestTypes: this._parseListToString(
          selectedRequestType,
          optionRequestType
        ),
        demandTypes: this._parseListToString(
          selectedDemandType,
          optionDemandType
        ),
        countries: this._parseListToString(selectedCountry, optionCountry),
      },
      true
    );

    return (
      <>
        <div className="border-bottom border bg-white">
          <h1 className="pb-2 pt-8" style={fixedWidthStyle}>
            <div className="text-base font-bold leading-none text-gray-600">
              Intowow Console
            </div>
            <div className="flex items-center">
              <div className="mr-4 flex items-center text-4xl font-extrabold text-gray-900">
                Inventory Revenue Analysis Dashboard
              </div>
            </div>
          </h1>
        </div>
        <div className="bg-gray-200 py-2">
          <div className="flex justify-between " style={fixedWidthStyle}>
            <div className="flex w-full items-center gap-2">
              <div className="z-50 w-1/3">
                <NetworksSelector
                  selectedId={selectedNetworkId}
                  handleOnChange={this.handleNetworksChanged}
                ></NetworksSelector>
              </div>
              <PopupWrapper
                ref={this.child}
                place="bottom left"
                triggerType="click"
                hideArrow={false}
                triggerElement={
                  <div className="border rounded hover:shadow focus:outline-none inline-flex cursor-pointer items-center border-gray-400 bg-white px-4 py-2 text-sm text-gray-700">
                    <span className="mr-4">
                      <span className="text-sm text-gray-600">
                        From <b className="text-gray-700">{startDate}</b> to{" "}
                        <b className="text-gray-700">{endDate}</b>
                      </span>
                    </span>

                    <IoIosArrowDown></IoIosArrowDown>
                  </div>
                }
                popupElement={
                  <DateRangePopover
                    startDate={startDate}
                    endDate={endDate}
                    dateRanges={[
                      DATE_RANGE_TYPE.LAST_6_MONTHS,
                      DATE_RANGE_TYPE.LAST_9_MONTHS,
                      DATE_RANGE_TYPE.LAST_1_YEAR,
                      DATE_RANGE_TYPE.LAST_FULL_YEAR,
                    ]}
                    minDate={moment().subtract(5, "year").toDate()}
                    handleClosePopover={this.handleClosePopover}
                    handleDateRangeFilterChanged={
                      this.handleDateRangeFilterChanged
                    }
                  ></DateRangePopover>
                }
              ></PopupWrapper>
              <label className="rounded mr-2 flex cursor-pointer items-center py-2">
                <Checkbox
                  isChecked={isForceReload}
                  onChange={this.handleForceReloadChanged}
                ></Checkbox>
                <span className="ml-1 align-middle text-sm font-semibold text-gray-600 hover:text-gray-700">
                  Force Reload
                </span>
              </label>
              <button
                type="button"
                onClick={this.handleGetData}
                className={`rounded px-6 py-2 font-semibold text-white ${
                  isGettingDataDisabled
                    ? "bg-blue-200"
                    : "shadow bg-blue-400 hover:bg-blue-500"
                }`}
              >
                Get data
              </button>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="sticky top-0 z-40">
            <div className="border-b bg-white">
              <div style={fixedWidthStyle}>
                {selectedNetworkId && options && (
                  <NetworkInfoHeader
                    networkId={selectedNetworkId}
                    className="py-3 pr-1"
                  ></NetworkInfoHeader>
                )}
              </div>
            </div>
            {isFolding && (
              <>
                <div className="border-b bg-white pt-1">
                  <div style={fixedWidthStyle}>
                    <div className="flex gap-4">
                      <div className="w-1/4">
                        {options && options.holistic && (
                          <>
                            <div className="text-center text-xs font-semibold leading-none text-gray-600">
                              Monthly Ad Request
                            </div>
                            <div className="text-center text-xl leading-tight text-gray-800">
                              {formatThousands(options.holistic.avgRequest)}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="border-l w-3/4 border-transparent pl-4">
                        {options && options.holistic && (
                          <div className="grid grid-cols-3 gap-4">
                            <div>
                              <div className="text-center text-xs font-semibold leading-none text-gray-600">
                                {`Monthly Revenue ${
                                  selectedCurrency
                                    ? `(${selectedCurrency})`
                                    : ""
                                }`}
                              </div>
                              <div className="text-center text-xl leading-tight text-gray-800">
                                {formatMoneyWithCurrency({
                                  value: options.holistic.avgRevenue,
                                  currency: selectedCurrency,
                                })}
                              </div>
                            </div>

                            <div>
                              <div className="text-center text-xs font-semibold leading-none text-gray-600">
                                {`Est. Monthly NIR ${
                                  selectedCurrency
                                    ? `(${selectedCurrency})`
                                    : ""
                                }`}
                              </div>
                              <div className="text-center text-xl leading-tight text-gray-800">
                                {formatMoneyWithCurrency({
                                  value:
                                    options.holistic.avgRevenue * 0.9 * 0.1,
                                  currency: selectedCurrency,
                                })}
                              </div>
                            </div>

                            <div>
                              <div className="text-center text-xs font-semibold leading-none text-gray-600">
                                {`Est. In2w Revenue ${
                                  selectedCurrency
                                    ? `(${selectedCurrency})`
                                    : ""
                                }`}
                              </div>
                              <div className="text-center text-xl leading-tight text-gray-800">
                                {formatMoneyWithCurrency({
                                  value:
                                    options.holistic.avgRevenue *
                                    0.9 *
                                    0.1 *
                                    0.3,
                                  currency: selectedCurrency,
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-b bg-gray-100">
                  <div className="p-2" style={fixedWidthStyle}>
                    <div className="text-xs text-gray-600">Filter by</div>
                    <div className="flex flex-col items-start gap-2">
                      <FilterInformation
                        title="Request Type"
                        count={selectedRequestType.length}
                        list={parseRequestType}
                      ></FilterInformation>
                      <FilterInformation
                        title="Country"
                        count={selectedCountry.length}
                        list={parseCountry}
                      ></FilterInformation>
                      <FilterInformation
                        title="Demand Type"
                        count={selectedDemandType.length}
                        list={parseDemandType}
                      ></FilterInformation>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="min-h-screen bg-white py-4" style={fixedWidthStyle}>
            {errMsg && <div className="text-center text-red-600">{errMsg}</div>}
            {isLoading && (
              <div
                className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <LoadingUI iconOnly={true}></LoadingUI>
              </div>
            )}
            {options && (
              <>
                {!isFolding && (
                  <>
                    {/* Holistic Summary */}
                    <div className="mb-6">
                      <div className="text-2xl font-bold leading-6">
                        Holistic Summary
                      </div>
                      <div className="mb-1 text-sm text-gray-600">
                        Holistic demand tunes only (
                        {HOLISTIC_DEMAND_TYPES.map((d) => _.startCase(d)).join(
                          ", "
                        )}
                        )
                      </div>
                      <div className="flex gap-4">
                        <div className="w-1/4">
                          {options && options.holistic && (
                            <div>
                              <CardWrapper
                                title="Monthly Ad Request"
                                notes="Average per month"
                              >
                                <div className="text-center text-3xl">
                                  {formatThousands(options.holistic.avgRequest)}
                                </div>
                              </CardWrapper>
                            </div>
                          )}
                        </div>
                        <div className="border-l w-3/4 pl-4">
                          {options && options.holistic && (
                            <div className="grid grid-cols-3 gap-4">
                              <CardWrapper
                                title={`Monthly Revenue ${
                                  selectedCurrency
                                    ? `(${selectedCurrency})`
                                    : ""
                                }`}
                                notes="Average per month"
                              >
                                <div className="text-center text-3xl">
                                  {formatMoneyWithCurrency({
                                    value: options.holistic.avgRevenue,
                                    currency: selectedCurrency,
                                  })}
                                </div>
                              </CardWrapper>
                              <CardWrapper
                                title={`Est. Monthly NIR ${
                                  selectedCurrency
                                    ? `(${selectedCurrency})`
                                    : ""
                                }`}
                                notes="Average per month *0.9 *0.1"
                              >
                                <div className="text-center text-3xl">
                                  {formatMoneyWithCurrency({
                                    value:
                                      options.holistic.avgRevenue * 0.9 * 0.1,
                                    currency: selectedCurrency,
                                  })}
                                </div>
                              </CardWrapper>
                              <CardWrapper
                                title={`Est. In2w Revenue ${
                                  selectedCurrency
                                    ? `(${selectedCurrency})`
                                    : ""
                                }`}
                                notes="Average per month *0.9 *0.1 *0.3"
                              >
                                <div className="text-center text-3xl">
                                  {formatMoneyWithCurrency({
                                    value:
                                      options.holistic.avgRevenue *
                                      0.9 *
                                      0.1 *
                                      0.3,
                                    currency: selectedCurrency,
                                  })}
                                </div>
                              </CardWrapper>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Filter  */}
                    <div className="rounded border mb-4 bg-gray-100 p-4">
                      <div className="flex items-center justify-between">
                        <div className="text-xs text-gray-600">
                          Sort by ad requests
                        </div>
                        <div className="cursor-pointer text-gray-700 hover:text-blue-500">
                          <ClickToCopyWrapper
                            copyText={`${window.location.origin}/#${this.props.location.pathname}?${queryString}`}
                            iconType="LINK"
                            buttonText="Share link"
                            tooltipMessage="Copy"
                          ></ClickToCopyWrapper>
                        </div>
                      </div>

                      <div className="flex flex-col gap-4">
                        {optionRequestType && (
                          <div className="flex flex-col gap-1">
                            <HotKeyList
                              title="Request Type"
                              count={selectedRequestType.length}
                              list={Array.from(hotkeyRequestType.keys())}
                              handleClickAll={() =>
                                this.handleFilterChanged(
                                  optionRequestType,
                                  optionRequestType,
                                  "selectedRequestType"
                                )
                              }
                              handleClick={(key) =>
                                this.handleHotKeyChanged(
                                  key,
                                  hotkeyRequestType,
                                  optionRequestType,
                                  "selectedRequestType"
                                )
                              }
                            ></HotKeyList>

                            <div
                              className="mb-2 w-2/3"
                              style={{ marginLeft: "7.5rem" }}
                            >
                              <Select
                                defaultValue={parseRequestType}
                                value={parseRequestType}
                                onChange={(value) =>
                                  this.handleFilterChanged(
                                    value,
                                    optionRequestType,
                                    "selectedRequestType"
                                  )
                                }
                                options={optionRequestType}
                                isSearchable={true}
                                isMulti={true}
                              />
                            </div>
                          </div>
                        )}

                        {optionCountry && (
                          <div className="flex flex-col gap-1">
                            <HotKeyList
                              title="Country"
                              count={selectedCountry.length}
                              list={Array.from(REGION_GROUP.keys())}
                              handleClickAll={() =>
                                this.handleFilterChanged(
                                  optionCountry,
                                  optionCountry,
                                  "selectedCountry"
                                )
                              }
                              handleClick={(key) =>
                                this.handleHotKeyChanged(
                                  key,
                                  REGION_GROUP,
                                  optionCountry,
                                  "selectedCountry"
                                )
                              }
                            ></HotKeyList>
                            <div
                              className="mb-2"
                              style={{
                                width: "calc(100% - 7.5rem)",
                                marginLeft: "7.5rem",
                              }}
                            >
                              <Select
                                defaultValue={parseCountry}
                                value={parseCountry}
                                onChange={(value) =>
                                  this.handleFilterChanged(
                                    value,
                                    optionCountry,
                                    "selectedCountry"
                                  )
                                }
                                options={optionCountry}
                                isSearchable={true}
                                isMulti={true}
                                styles={{
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    height: "4rem",
                                    overflow: "auto",
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        )}

                        {optionDemandType && (
                          <div className="flex flex-col gap-1">
                            <HotKeyList
                              title="Demand Type"
                              count={selectedDemandType.length}
                              list={Array.from(hotkeyDemandType.keys())}
                              handleClickAll={() =>
                                this.handleFilterChanged(
                                  optionDemandType,
                                  optionDemandType,
                                  "selectedDemandType"
                                )
                              }
                              handleClick={(key) =>
                                this.handleHotKeyChanged(
                                  key,
                                  hotkeyDemandType,
                                  optionDemandType,
                                  "selectedDemandType"
                                )
                              }
                            ></HotKeyList>
                            <div
                              style={{
                                width: "calc(100% - 7.5rem)",
                                marginLeft: "7.5rem",
                              }}
                            >
                              <Select
                                defaultValue={parseDemandType}
                                value={parseDemandType}
                                onChange={(value) =>
                                  this.handleFilterChanged(
                                    value,
                                    optionDemandType,
                                    "selectedDemandType"
                                  )
                                }
                                options={optionDemandType}
                                isSearchable={true}
                                isMulti={true}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className="flex justify-end text-sm font-semibold text-gray-700">
                  Data last updated:{" "}
                  <DateTimeFormatter
                    datetime={dataUpdatedTime}
                  ></DateTimeFormatter>
                </div>

                {/* Summary */}
                <div className="mb-8 mt-4">
                  <div className="text-2xl font-bold">Summary</div>
                  <div className="flex gap-4">
                    <div className="w-1/4">
                      {options && options.summary && (
                        <div>
                          <CardWrapper
                            title="Monthly Ad Request"
                            notes="Average per month"
                          >
                            <div className="text-center text-3xl">
                              {formatThousands(options.summary.avgRequest)}
                            </div>
                          </CardWrapper>
                        </div>
                      )}
                    </div>
                    <div className="border-l w-3/4 pl-4">
                      {options && options.summary && (
                        <div className="grid grid-cols-3 gap-4">
                          <CardWrapper
                            title={`Monthly Revenue ${
                              selectedCurrency ? `(${selectedCurrency})` : ""
                            }`}
                            notes="Average per month"
                          >
                            <div className="text-center text-3xl">
                              {/* {formatThousands(options.summary.avgRevenue)} */}
                              {formatMoneyWithCurrency({
                                value: options.summary.avgRevenue,
                                currency: selectedCurrency,
                              })}
                            </div>
                          </CardWrapper>
                          <CardWrapper
                            title={`Est. Monthly NIR ${
                              selectedCurrency ? `(${selectedCurrency})` : ""
                            }`}
                            notes="Average per month *0.9 *0.1"
                          >
                            <div className="text-center text-3xl">
                              {/* {formatThousands(
                              options.summary.avgRevenue * 0.9 * 0.1
                            )} */}
                              {formatMoneyWithCurrency({
                                value: options.summary.avgRevenue * 0.9 * 0.1,
                                currency: selectedCurrency,
                              })}
                            </div>
                          </CardWrapper>
                          <CardWrapper
                            title={`Est. In2w Revenue ${
                              selectedCurrency ? `(${selectedCurrency})` : ""
                            }`}
                            notes="Average per month *0.9 *0.1 *0.3"
                          >
                            <div className="text-center text-3xl">
                              {/* {formatThousands(
                              options.summary.avgRevenue * 0.9 * 0.1 * 0.3
                            )} */}
                              {formatMoneyWithCurrency({
                                value:
                                  options.summary.avgRevenue * 0.9 * 0.1 * 0.3,
                                currency: selectedCurrency,
                              })}
                            </div>
                          </CardWrapper>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Request Type Distribution Trend / Request Type % Piechart */}
                <div className="mb-8 grid grid-cols-6 gap-4">
                  <div className="col-span-4">
                    <div className="text-2xl font-bold">
                      Request Type Distribution Trend
                    </div>
                    <CardWrapper>
                      {options && options[REPORTKEY.REQUEST] && (
                        <HighchartWrapper
                          options={options[REPORTKEY.REQUEST].trend}
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                  <div className="col-span-2">
                    <div className="text-2xl font-bold">
                      Request Type % Piechart
                    </div>
                    <CardWrapper>
                      {options && options[REPORTKEY.REQUEST] && (
                        <HighchartWrapper
                          options={options[REPORTKEY.REQUEST].percent}
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                </div>
                {/* Device Category Distribution Trend / Device Category % Piechart */}
                <div className="mb-8 grid grid-cols-6 gap-4">
                  <div className="col-span-4">
                    <div className="text-2xl font-bold">
                      Device Category Distribution Trend
                    </div>
                    <CardWrapper>
                      {options && options[REPORTKEY.DEVICE] && (
                        <HighchartWrapper
                          options={options[REPORTKEY.DEVICE].trend}
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                  <div className="col-span-2">
                    <div className="text-2xl font-bold">
                      Device Category % Piechart
                    </div>
                    <CardWrapper>
                      {options && options[REPORTKEY.DEVICE] && (
                        <HighchartWrapper
                          options={options[REPORTKEY.DEVICE].percent}
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                </div>
                {/* Country Distribution Trend / Country % Piechart */}
                <div className="mb-8 grid grid-cols-6 gap-4">
                  <div className="col-span-4">
                    <div className="text-2xl font-bold">
                      Country Distribution Trend
                    </div>
                    <CardWrapper>
                      {options && options[REPORTKEY.COUNTRY] && (
                        <HighchartWrapper
                          options={options[REPORTKEY.COUNTRY].trend}
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                  <div className="col-span-2">
                    <div className="text-2xl font-bold">Country % Piechart</div>
                    <CardWrapper>
                      {options && options[REPORTKEY.COUNTRY] && (
                        <HighchartWrapper
                          options={options[REPORTKEY.COUNTRY].percent}
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                </div>
                {/* Demand Type Impressions Distribution Trend / Demand Type Impressions % Piechart */}
                <div className="mb-8 grid grid-cols-6 gap-4">
                  <div className="col-span-4">
                    <Title>Demand Type Impressions Distribution Trend</Title>
                    <CardWrapper>
                      {options && options[REPORTKEY.DEMAND] && (
                        <HighchartWrapper
                          options={
                            options[REPORTKEY.DEMAND][DEMAND.IMPRESSION].trend
                          }
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                  <div className="col-span-2">
                    <Title>Demand Type Impressions % Piechart</Title>
                    <CardWrapper>
                      {options && options[REPORTKEY.DEMAND] && (
                        <HighchartWrapper
                          options={
                            options[REPORTKEY.DEMAND][DEMAND.IMPRESSION].percent
                          }
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                </div>
                {/* Demand Type Revenue Distribution Trend / Demand Type Revenue % Piechart */}
                <div className="mb-8 grid grid-cols-6 gap-4">
                  <div className="col-span-4">
                    <div className="text-2xl font-bold">
                      Demand Type Revenue Distribution Trend{" "}
                      {selectedCurrency ? `(${selectedCurrency})` : ""}
                    </div>
                    <CardWrapper>
                      {options && options[REPORTKEY.DEMAND] && (
                        <HighchartWrapper
                          options={
                            options[REPORTKEY.DEMAND][DEMAND.REVENUE].trend
                          }
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                  <div className="col-span-2">
                    <div className="text-2xl font-bold">
                      Demand Type Revenue % Piechart
                    </div>
                    <CardWrapper>
                      {options && options[REPORTKEY.DEMAND] && (
                        <HighchartWrapper
                          options={
                            options[REPORTKEY.DEMAND][DEMAND.REVENUE].percent
                          }
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                </div>
                {/* eCPM Distribution Trend / Average eCPM */}
                <div className="mb-8 grid grid-cols-6 gap-4">
                  <div className="col-span-4">
                    <div className="text-2xl font-bold">
                      eCPM Distribution Trend
                    </div>
                    <CardWrapper>
                      {options && options.ecpm && (
                        <HighchartWrapper
                          options={options.ecpm.trend}
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                  <div className="col-span-2">
                    <div className="text-2xl font-bold">Average eCPM</div>
                    <CardWrapper>
                      {options && options.ecpm && (
                        <HighchartWrapper
                          options={options.ecpm.percent}
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                </div>
                {/* Fill Rate Trend / Average Fill Rate / Average Drop off Rate */}
                <div className="mb-8 grid grid-cols-6 gap-4">
                  <div className="col-span-4">
                    <div className="text-2xl font-bold">Fill Rate Trend</div>
                    <CardWrapper>
                      {options && options.fillRate && (
                        <HighchartWrapper
                          options={options.fillRate.trend}
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                  <div className="col-span-2">
                    <div className="text-2xl font-bold">Average Fill Rate</div>
                    <CardWrapper>
                      {options && options.fillRate && (
                        <HighchartWrapper
                          options={options.fillRate.avg}
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                </div>
                {/* CSC Drop Off Rate Trend / Average Drop off Rate */}
                <div className="mb-8 grid grid-cols-6 gap-4">
                  <div className="col-span-4">
                    <div className="text-2xl font-bold">
                      CSC Drop Off Rate Trend
                    </div>
                    <CardWrapper>
                      {options && options.dropoff && (
                        <HighchartWrapper
                          options={options.dropoff.trend}
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                  <div className="col-span-2">
                    <div className="text-2xl font-bold">
                      Average Drop off Rate
                    </div>
                    <CardWrapper>
                      {options && options.dropoff && (
                        <HighchartWrapper
                          options={options.dropoff.avg}
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                </div>
                {/* CSC Drop Off Rate Trend by demand type / Average CSC Drop off rate */}
                <div className="mb-8 grid grid-cols-6 gap-4">
                  <div className="col-span-4">
                    <div className="text-2xl font-bold">
                      CSC Drop Off Rate Trend by demand type
                    </div>
                    <CardWrapper>
                      {options && options[REPORTKEY.DEMAND] && (
                        <HighchartWrapper
                          options={
                            options[REPORTKEY.DEMAND][DEMAND.DROPOFFF].trend
                          }
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                  <div className="col-span-2">
                    <div className="text-2xl font-bold">
                      Average CSC Drop off rate
                    </div>
                    <CardWrapper>
                      {options && options[REPORTKEY.DEMAND] && (
                        <HighchartWrapper
                          options={
                            options[REPORTKEY.DEMAND][DEMAND.DROPOFFF].percent
                          }
                        ></HighchartWrapper>
                      )}
                    </CardWrapper>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default InventoryRevenueAnalysisDashboard;

class CardWrapper extends React.Component {
  render() {
    const { children, title = "", titleClass = "", notes = "" } = this.props;

    return (
      <div className="border rounded shadow px-4 py-2">
        <div
          className={`w-full text-center text-sm font-semibold text-gray-700 ${titleClass}`}
        >
          {title}
        </div>
        {children}
        <div className="w-full text-center text-sm text-gray-500">{notes}</div>
      </div>
    );
  }
}

class Title extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className="truncate text-2xl font-bold	" title={children}>
        {children}
      </div>
    );
  }
}

class ActionButton extends React.Component {
  render() {
    const { handleClick, children } = this.props;

    return (
      <button
        className="rounded border border-blue-400 px-2 text-sm font-semibold text-blue-400"
        onClick={handleClick}
      >
        {children}
      </button>
    );
  }
}

class HotKeyList extends React.Component {
  render() {
    const { title, count, list, handleClickAll, handleClick } = this.props;
    return (
      <div className="flex items-center gap-2 text-gray-800">
        <div className="font-semibold" style={{ width: "7rem" }}>
          {title}:
        </div>
        <div className="border-r pr-2 text-center" style={{ width: "2.5rem" }}>
          {count}
        </div>
        <ActionButton handleClick={handleClickAll}>All</ActionButton>
        {_.map(list, (key) => {
          return (
            <ActionButton key={key} handleClick={() => handleClick(key)}>
              {_.startCase(key)}
            </ActionButton>
          );
        })}
      </div>
    );
  }
}

class FilterInformation extends React.Component {
  render() {
    const { title, count, list } = this.props;
    return (
      <div className="flex w-full items-center gap-2 text-gray-800">
        <div className="font-semibold" style={{ width: "7rem" }}>
          {title}:
        </div>
        <div
          className="border-r pr-2 text-center font-semibold text-gray-600"
          style={{ width: "2.5rem" }}
        >
          {count}
        </div>
        <div className="truncate" style={{ width: "calc(100% - 10.5rem)" }}>
          {(list || []).map((d) => d.label.split("(")[0]).join(" / ")}
        </div>
      </div>
    );
  }
}
