import React from "react";
import { Switch, Route, Link } from "react-router-dom";

import Publishers from "./publishers/Publishers.js";
import UpdatePublisherTrialStatus from "./publishers/UpdatePublisherTrialStatus";
import CreateReseller from "./publishers/CreateReseller";
import CreatePublisher from "./publishers/CreatePublisher";
import PublisherBillingSettingViewer from "./publishers/billing-setting/PublisherBillingSettingViewer.js";
import PricingConfigViewer from "./publishers/pricing-config/PricingConfigViewer.js";
import GamAccountsViewer from "./publishers/gam-accounts/GamAccountsViewer.js";

import OnboardAdUnits from "./OnboardAdUnits";
import UpdateNetworkAdServingCost from "./UpdateNetworkAdServingCost";
import NetworkUPRSettingViewer from "./custom-upr/NetworkUPRSettingViewer.js";
import NetworkUPRSegmentationViewer from "./upr-segmentation/NetworkUPRSegmentationViewer";

import Networks from "./Networks";

import NetworkCSTConfigViewer from "./cst-config/NetworkCSTConfigViewer";
import NetworkFeaturesViewer from "./network-features/NetworkFeaturesViewer";
import PublisherFeaturesViewer from "./network-features/PublisherFeaturesViewer";
import CodeSnippetViewer from "./code-snippet/CodeSnippetViewer";
import VirtualPlacementsViewer from "./virtual-placements/VirtualPlacementsViewer.js";
import CreateVirtualPlacementViewer from "./virtual-placements/CreateOrUpdateVirtualPlacement.js";
import NetworkPrebidSettingViewer from "./prebid-setting/NetworkPrebidSettingViewer.js";
import NetworkPrebidSummaryViewer from "./prebid-setting/NetworkPrebidSummaryViewer.js";
import BatchUpdateAdUnits from "./BatchUpdateAdUnits.js";
import AcRawConfigViewer from "./AcRawConfigViewer.js";

class AccountViewer extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <div className="bg-blue-900 px-2">
          <div className="text-sm font-bold text-blue-100">
            Account Services:
            <span className="mx-2 text-blue-100">
              <Link to="/ops-mgmt/account/publishers">Publishers</Link>
            </span>
            <span className="mx-2 text-blue-100">
              <Link to="/ops-mgmt/account/networks">Networks</Link>
            </span>
            <span className="mx-2 text-blue-100">
              <Link to="/ops-mgmt/account/onboard-ad-units">
                Batch Onboard Ad Units
              </Link>
            </span>
          </div>
        </div>

        <>
          <Switch>
            <Route
              path="/ops-mgmt/account/publishers"
              component={Publishers}
            ></Route>

            <Route
              path="/ops-mgmt/account/networks"
              component={Networks}
            ></Route>

            <Route
              path="/ops-mgmt/account/network/:networkId/update-ad-serving-cost"
              component={UpdateNetworkAdServingCost}
            ></Route>

            <Route
              path="/ops-mgmt/account/network/:networkId/upr-setting"
              component={NetworkUPRSettingViewer}
            ></Route>

            <Route
              path="/ops-mgmt/account/network/:networkId/upr-segmentation"
              component={NetworkUPRSegmentationViewer}
            ></Route>

            <Route
              path="/ops-mgmt/account/network/:networkId/cst-config"
              component={NetworkCSTConfigViewer}
            ></Route>

            <Route
              path="/ops-mgmt/account/network/:networkId/features"
              component={NetworkFeaturesViewer}
            ></Route>

            <Route
              path="/ops-mgmt/account/network/:networkId/code-snippet"
              component={CodeSnippetViewer}
            ></Route>

            <Route
              path="/ops-mgmt/account/network/:networkId/virtual-placement"
              component={VirtualPlacementsViewer}
            ></Route>

            <Route
              path="/ops-mgmt/account/network/:networkId/prebid-settings"
              component={NetworkPrebidSettingViewer}
            ></Route>

            <Route
              path="/ops-mgmt/account/network/:networkId/prebid-summary"
              component={NetworkPrebidSummaryViewer}
            ></Route>

            <Route
              path="/ops-mgmt/account/network/:networkId/ac-raw-config"
              component={AcRawConfigViewer}
            ></Route>

            <Route
              path="/ops-mgmt/account/publisher/:pubId/update-trial-status"
              component={UpdatePublisherTrialStatus}
            ></Route>

            <Route
              path="/ops-mgmt/account/publisher/:pubId/features"
              component={PublisherFeaturesViewer}
            ></Route>

            <Route
              path="/ops-mgmt/account/publisher/:publisherId/billing-setting"
              component={PublisherBillingSettingViewer}
            ></Route>

            <Route
              path="/ops-mgmt/account/publisher/:publisherId/pricing-config"
              component={PricingConfigViewer}
            ></Route>

            <Route
              path="/ops-mgmt/account/publisher/:publisherId/ac-raw-config"
              component={AcRawConfigViewer}
            ></Route>

            <Route
              path="/ops-mgmt/account/publisher/:publisherId/gam-accounts"
              component={GamAccountsViewer}
            ></Route>

            <Route
              path="/ops-mgmt/account/onboard-ad-units"
              component={OnboardAdUnits}
            ></Route>

            <Route
              path="/ops-mgmt/account/batch-update-ad-units"
              component={BatchUpdateAdUnits}
            ></Route>

            <Route
              path="/ops-mgmt/account/create-reseller"
              component={CreateReseller}
            ></Route>

            <Route
              path="/ops-mgmt/account/create-publisher"
              component={CreatePublisher}
            ></Route>
          </Switch>
        </>
      </div>
    );
  }
}

export default AccountViewer;
