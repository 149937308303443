import React from "react";
import { notify } from "react-notify-toast";
import ReactTooltip from "react-tooltip";
import { FiExternalLink } from "react-icons/fi";
import { GamAccountAPI } from "apis";
import _ from "lodash";

import PublisherInfoHeader from "components/ops-mgmt/account/network-features/PublisherInfoHeader";
import LoadingUI from "components/common/LoadingUI";
import ModalWrapper from "components/common/ModalWrapper";
import NetworkStatusIndicator from "components/common/NetworkStatusIndicator";
import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import PromptModal from "components/common/PromptModal";
import OnboardNetworkModal from "./OnboardNetworkModal";
import { MESSAGE } from "constants/Message";

import {
  thClass,
  tdClass,
  inputClass,
  buttonConfirmClass,
  buttonActionClass,
  buttonActionDisable,
  buttonModalConfirmDisable,
  buttonModalConfirmClass,
  buttonModalCancelClass,
} from "helpers/StyleClass.js";

import { GAM_CONSOLE_URL } from "constants/GAMUrl";

const PROBLEM = {
  NORMAL: "NORMAL",
  UNAUTHORIZED: "UNAUTHORIZED",
};

class GamAccountsViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isOpenViewModal: false,
      isOpenPromptModal: false,
      isOpenNetworkModal: false,
      intervalId: null,
      msg: null,

      pubId: null,
      timestamp: null,
      authCode: null,
      name: "",
      data: [],
      selectedAccount: null,
      promptHeader: null,
      promptMessage: null,
      promptAction: null,
    };

    this.handleAccessOAuth = this.handleAccessOAuth.bind(this);
    this.handleGetAuthMessage = this.handleGetAuthMessage.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleAuthorize = this.handleAuthorize.bind(this);
    this.handleSync = this.handleSync.bind(this);
    this.handleGoToGAMConsole = this.handleGoToGAMConsole.bind(this);
    this.handleOpenNetworkModal = this.handleOpenNetworkModal.bind(this);
    this.handleCloseNetworkModal = this.handleCloseNetworkModal.bind(this);
    this.handleFinishNetworkOnboard =
      this.handleFinishNetworkOnboard.bind(this);

    this.updateData = this.updateData.bind(this);
  }

  async componentDidMount() {
    const { publisherId } = this.props.match.params;
    this.setState({ isLoading: true });

    const result = await GamAccountAPI.getGamAccounts({ pubId: publisherId });
    if (result) {
      this.setState({ data: result });
    }
    this.setState({ pubId: publisherId, isLoading: false });
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    if (intervalId) {
      clearInterval(intervalId);
    }
    window.removeEventListener("message", this.handleGetAuthMessage);
  }

  // Access Google OAuth2.0
  handleAccessOAuth() {
    try {
      this.setState({ isLoading: true });
      window.removeEventListener("message", this.handleGetAuthMessage);

      const timestamp = Date.now();
      const access = {
        url: "https://accounts.google.com/o/oauth2/v2/auth",
        params: {
          scope: encodeURIComponent("https://www.googleapis.com/auth/dfp"),
          include_granted_scopes: true,
          redirect_uri: this._getRedirectUri(),
          response_type: "code",
          client_id: process.env.REACT_APP_GOOGLE_SERVICE_CLIENT_ID,
          prompt: "consent",
          access_type: "offline",
          state: encodeURIComponent(JSON.stringify({ timestamp })),
        },
      };

      const accessUrl = `${access.url}?${Object.entries(access.params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`;

      const width = 500;
      const height = 600;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2;

      const popup = window.open(
        accessUrl,
        "Google OAuth2.0",
        `width=${width},height=${height},left=${left},top=${top}`
      );

      this.setState({ timestamp });

      window.addEventListener("message", this.handleGetAuthMessage);

      const checkWindow = setInterval(() => {
        try {
          if (popup && popup.closed) {
            clearInterval(checkWindow);
            this.setState({ isLoading: false, intervalId: null });
            window.removeEventListener("message", this.handleGetAuthMessage);
          }
        } catch (error) {
          console.log("Popup has been closed");
        }
      }, 1000);

      this.setState({ intervalId: checkWindow });
    } catch (error) {
      notify.show("Could not open Google OAuth", "error");
      console.error(error);
    }
  }

  _getRedirectUri() {
    return encodeURIComponent(`${window.location.origin}/oauth-callback.html`);
  }

  // Get Google OAuth2.0 AuthCode
  handleGetAuthMessage(event) {
    if (event.origin !== window.location.origin) return;

    const {
      data: { code, state, error },
    } = event;

    if (error) {
      notify.show(error, MESSAGE.ERROR);
      return;
    }

    if (!code || !state) return;

    const { timestamp } = JSON.parse(decodeURIComponent(state));
    if (timestamp !== this.state.timestamp) return;

    window.removeEventListener("message", this.handleGetAuthMessage);
    this.setState({ isLoading: false, authCode: code }, () => {
      if (this.state.selectedAccount) {
        this.handleAuthorize(this.state.selectedAccount);
      } else {
        this.setState({ isOpenViewModal: true, msg: null });
      }
    });
  }

  handleInputChange(e) {
    this.setState({ name: e.target.value });
  }

  handleClose() {
    this.setState({
      isOpenViewModal: false,
      authCode: null,
      name: "",
      msg: null,
    });
  }

  // Confirm for Add GAM Account
  async handleConfirm() {
    const { authCode, pubId, name } = this.state;
    if (!authCode) return;

    this.setState({ isLoading: true, msg: null });
    try {
      const result = await GamAccountAPI.createGamAccount({
        pubId,
        name,
        authorizationCode: authCode,
        redirectUri: this._getRedirectUri(),
      });

      if (result) {
        this.updateData(result);
        this.setState({ msg: ["Add Success", MESSAGE.SUCCESS] });
      }
    } catch (error) {
      this.setState({ msg: [error, MESSAGE.ERROR] });
    }

    this.setState({ isLoading: false });
  }

  // Action: Authorize GAM Account
  async handleAuthorize({ accountId, name }, isPrompt = true) {
    if (isPrompt) {
      this.setState({
        isPromptModalOpen: true,
        msg: null,
        promptHeader: "Authorize GAM Account",
        promptMessage: (
          <>
            Are you sure you want to authorize this GAM account? <br />
            <span className="font-semibold text-blue-600">
              {accountId} {name}
            </span>
          </>
        ),
        promptAction: () => this.handleAuthorize({ accountId }, false),
      });
      return;
    }

    this.setState({ isLoading: true });
    try {
      const result = await GamAccountAPI.authorizeGamAccount({
        accountId,
        authorizationCode: this.state.authCode,
        redirectUri: this._getRedirectUri(),
      });

      if (result) {
        this.updateData(result);
        this.setState({ msg: ["Authorize Success", MESSAGE.SUCCESS] });
      }
    } catch (error) {
      this.setState({ msg: [error, MESSAGE.ERROR] });
    }

    this.setState({ isLoading: false });
  }

  // Action: Sync GAM Account
  async handleSync({ accountId, name }, isPrompt = true) {
    if (isPrompt) {
      this.setState({
        isPromptModalOpen: true,
        msg: null,
        promptHeader: "Sync GAM Account",
        promptMessage: (
          <>
            Are you sure you want to sync this GAM account? <br />
            <span className="font-semibold text-blue-600">
              {accountId} {name}
            </span>
          </>
        ),
        promptAction: () => this.handleSync({ accountId }, false),
      });
      return;
    }

    this.setState({ isLoading: true });
    try {
      const result = await GamAccountAPI.syncGamAccount({ accountId });

      if (result) {
        this.updateData(result);
        this.setState({ msg: ["Sync Success", MESSAGE.SUCCESS] });
      }
    } catch (error) {
      this.setState({ msg: [error, MESSAGE.ERROR] });
    }

    this.setState({ isLoading: false });
  }

  handleGoToGAMConsole(networkCode) {
    window.open(`${GAM_CONSOLE_URL}/${networkCode}`);
  }

  handleOpenNetworkModal(account) {
    this.setState({ isOpenNetworkModal: true, selectedAccount: account });
  }

  handleCloseNetworkModal() {
    this.setState({ isOpenNetworkModal: false, selectedAccount: null });
  }

  handleFinishNetworkOnboard({ accountId, network }) {
    const account = this.state.data.find(
      (item) => item.accountId === accountId
    );
    this.updateData({
      ...account,
      gamNetworks: _.sortBy([...account.gamNetworks, network], ["networkId"]),
    });
  }

  updateData(account) {
    const data = _.cloneDeep(this.state.data);
    const index = data.findIndex(
      (item) => item.accountId === account.accountId
    );
    if (index < 0) {
      data.push(account);
    } else {
      data[index] = account;
    }
    this.setState({ data });
  }

  render() {
    const {
      isLoading,
      msg,
      isPromptModalOpen,
      isOpenViewModal,
      isOpenNetworkModal,
      pubId,
      data,
      name,
      selectedAccount,
      promptHeader,
      promptAction,
      promptMessage,
    } = this.state;
    return (
      <>
        {isLoading && (
          <div
            className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              zIndex: 101,
            }}
          >
            <LoadingUI iconOnly={true}></LoadingUI>
          </div>
        )}
        {pubId && <PublisherInfoHeader pubId={pubId}></PublisherInfoHeader>}
        <div className="min-h-screen bg-white px-12 py-8">
          <div className="mb-2 text-3xl font-extrabold">
            Onboard GAM Accounts
          </div>
          <div className="relative">
            <button
              className={`absolute right-0 ${buttonConfirmClass}`}
              style={{ top: "-3.5rem" }}
              onClick={() => {
                this.setState({ selectedAccount: null });
                this.handleAccessOAuth();
              }}
            >
              Add Account
            </button>
            <table className="shadow divide-y min-w-full">
              <thead className="bg-gray-100">
                <tr>
                  <th className={`${thClass} w-32`}>Account ID</th>
                  <th className={`${thClass} w-64`}>Name</th>
                  <th className={`${thClass} w-48`}>Status</th>
                  <th className={`${thClass} w-48`}>Problems</th>
                  <th className={thClass}>Networks</th>
                  <th className={`${thClass} w-56`}>Create At</th>
                  <th className={`${thClass}`} style={{ width: "18rem" }}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y bg-white text-sm">
                {data.map((item, idx) => (
                  <tr key={`${item.accountId}-${idx}`}>
                    <td className={tdClass}>{item.accountId}</td>
                    <td className={tdClass}>{item.name}</td>
                    <td className={tdClass}>{item.status}</td>
                    <td
                      className={`${tdClass} ${
                        item.problems.indexOf(PROBLEM.UNAUTHORIZED) !== -1
                          ? "font-semibold text-red-400"
                          : ""
                      }`}
                    >
                      {item.problems.indexOf(PROBLEM.UNAUTHORIZED) !== -1
                        ? _.startCase(_.toLower(PROBLEM.UNAUTHORIZED))
                        : "--"}
                    </td>
                    <td className={tdClass}>
                      {item.gamNetworks.length === 0
                        ? "--"
                        : item.gamNetworks.map((network) => (
                            <div
                              className="mb-1 flex items-center gap-1"
                              key={network.networkId}
                            >
                              <NetworkStatusIndicator
                                status={network.status}
                              ></NetworkStatusIndicator>
                              <span>{network.networkId}</span>
                              <div className="font-medium">{network.name}</div>
                              <div className="text-xs text-blue-700">
                                <ClickToCopyWrapper
                                  copyText={`${GAM_CONSOLE_URL}/${network.code}`}
                                  tooltipMessage="Copy GAM console link"
                                ></ClickToCopyWrapper>
                              </div>

                              <div>
                                <button
                                  type="button"
                                  className="rounded hover:shadow flex items-center bg-gray-100 text-xs text-blue-700"
                                  onClick={() =>
                                    this.handleGoToGAMConsole(network.code)
                                  }
                                  data-tip
                                  data-for={`${network.code}-external-link`}
                                >
                                  <FiExternalLink></FiExternalLink>
                                </button>
                                <ReactTooltip
                                  id={`${network.code}-external-link`}
                                  type="dark"
                                  effect="solid"
                                >
                                  Open this network's GAM console
                                </ReactTooltip>
                              </div>
                            </div>
                          ))}
                    </td>
                    <td className={tdClass}>
                      <DateTimeFormatter
                        datetime={item.createdAt}
                        isLineBreak={true}
                      ></DateTimeFormatter>
                    </td>
                    <td className={tdClass}>
                      <button
                        className={
                          item.problems.indexOf(PROBLEM.UNAUTHORIZED) !== -1
                            ? buttonActionClass
                            : buttonActionDisable
                        }
                        onClick={() => {
                          this.setState({ selectedAccount: item });
                          this.handleAccessOAuth();
                        }}
                        disabled={
                          item.problems.indexOf(PROBLEM.UNAUTHORIZED) === -1
                        }
                      >
                        Authorize
                      </button>
                      <button
                        className={buttonActionClass}
                        onClick={() => this.handleSync(item)}
                      >
                        Sync
                      </button>
                      <button
                        className={buttonActionClass}
                        onClick={() => this.handleOpenNetworkModal(item)}
                      >
                        Onboard Network
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <ModalWrapper
          isOpen={isOpenViewModal}
          showCloseFooter={false}
          customOverlayStyle={{ zIndex: 100 }}
          width="30%"
        >
          <div>
            <div className="mb-2 text-lg font-semibold">New GAM Account</div>

            <label>Name</label>
            <input
              type="text"
              className={inputClass}
              placeholder="example@domain.com..."
              onChange={this.handleInputChange}
            ></input>

            {msg && (
              <div
                className={`mt-2 ${
                  msg[1] === "error" ? "text-red-500" : "text-green-500"
                }`}
              >
                {msg[0]}
              </div>
            )}
            <div className="mt-2 flex items-center justify-end gap-2">
              {msg && msg[1] === MESSAGE.SUCCESS ? (
                <>
                  <button
                    type="button"
                    className={buttonModalConfirmClass}
                    onClick={this.handleClose}
                  >
                    Close
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className={buttonModalCancelClass}
                    onClick={this.handleClose}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className={
                      name !== ""
                        ? buttonModalConfirmClass
                        : buttonModalConfirmDisable
                    }
                    onClick={this.handleConfirm}
                    disabled={name === ""}
                  >
                    Proceed
                  </button>
                </>
              )}
            </div>
          </div>
        </ModalWrapper>

        {isPromptModalOpen && (
          <PromptModal
            isOpenModal={isPromptModalOpen}
            handleConfirm={promptAction}
            msg={msg}
            handleCancel={() =>
              this.setState({ isPromptModalOpen: false, msg: null })
            }
            header={promptHeader}
          >
            {promptMessage}
          </PromptModal>
        )}

        {isOpenNetworkModal && (
          <OnboardNetworkModal
            accountId={selectedAccount.accountId}
            onFinished={this.handleFinishNetworkOnboard}
            onClose={this.handleCloseNetworkModal}
            onLoading={(isLoading) => this.setState({ isLoading })}
          ></OnboardNetworkModal>
        )}
      </>
    );
  }
}

export default GamAccountsViewer;
