import React from "react";
import ModalWrapper from "components/common/ModalWrapper";
import { MESSAGE } from "constants/Message";

import {
  buttonModalCancelClass,
  buttonModalConfirmClass,
} from "helpers/StyleClass";

class PromptModal extends React.PureComponent {
  render() {
    const { isOpenModal, handleConfirm, handleCancel, header, children, msg } =
      this.props;

    return (
      <ModalWrapper
        isOpen={isOpenModal}
        showCloseFooter={false}
        customOverlayStyle={{ zIndex: 100 }}
        width="30%"
      >
        <div>
          <div className="mb-2 text-xl font-bold text-gray-700">
            {header || "Caution"}
          </div>

          <div className="mb-4 text-gray-600">{children}</div>

          {msg && (
            <div
              className={`mb-1 mt-2 ${
                msg[1] === MESSAGE.ERROR ? "text-red-500" : "text-green-500"
              }`}
            >
              {msg[0]}
            </div>
          )}
          <div className="flex items-end justify-end gap-2">
            {msg && msg[1] === MESSAGE.SUCCESS ? (
              <>
                <button
                  type="button"
                  className={buttonModalConfirmClass}
                  onClick={handleCancel}
                >
                  Close
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className={buttonModalCancelClass}
                  onClick={handleCancel}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className={buttonModalConfirmClass}
                  onClick={handleConfirm}
                >
                  Proceed
                </button>
              </>
            )}
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default PromptModal;
